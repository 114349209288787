<template>
  <div class="c-alpha-notif-change">
		<!-- <header id="header" class="c-alpha-notif-change__header"></header> -->
		<section class="c-alpha-notif-change__email-content">
			<div class="c-alpha-notif-change__email-container">
				<div class="c-alpha-notif-change__email-content-main">
					<div class="c-alpha-notif-change__email-content-main-centermail">
						<img src="@/assets/images/old-alpha/reset-sent.png" alt="Success" class=" c-alpha-notif-change__email-content-main-centermail-img">
						<h2 translate="notif_changepwd_title" class="c-alpha-notif-change__email-content-main-centermail-title">Your password is <span class="c-alpha-notif-change__email-content-main-centermail-title_blue">successfully changed</span></h2>
						<p translate="notif_changepwd_signin" class="c-alpha-notif-change__email-content-main-centermail-subtitle">Please <a @click='routerRedirect("/signin")' class="c-alpha-notif-change__email-content-main-centermail-subtitle_underline">sign in</a> again with your new password</p>
						<a @click='routerRedirect("/")' id="returnhome" translate="email_forgetpwd_return" class="c-alpha-notif-change__email-content-main-centermail-return">Return to Home</a><span class="c-alpha-notif-change__email-content-main-centermail-return_arrow"> >></span>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>
